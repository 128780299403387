<template>
    <div class="quemSomos">
        <div class="container-fluid px-0">
            <div class="row align-items-center justify-content-center">
                <div class="col-12 stripe-us text-center" title="Autor: Flávio Uemori Yamamoto; Nome comum: Verme Aveludado">
                    <span class="tit1">Quem</span><br />
                    <span class="tit2">Somos</span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="ball-list row d-flex align-items-center justify-content-center">
                <InfoBall title="Coordenadores de Táxon" text="Especialistas da comunidade científica responsáveis por toda a orientação e decisões científicas relacionadas à avaliação." color="#E2F5A1"/>
                <InfoBall title="Pontos Focais" text="Servidores do ICMBio encarregados da condução do processo de avaliação de determinado grupo taxonômico." color="#E2F5A1"/>
                <InfoBall title="Equipe de Apoio" text="Especialistas (profissionais ou estudantes) que contribuem para a consolidação de informações e execução do processo de avaliação junto aos Centros de Pesquisa do ICMBio." color="#E2F5A1"/>
            </div>
            <div class="row text-left my-5" >
                <div class="col-12">
                    <p class="text-justify">A avaliação do risco de extinção das espécies da fauna brasileira é executada por uma rede de especialistas que realiza um diagnóstico técnico-científico sobre o estado de conservação da fauna. Essa rede é capitaneada pelos Centros de Pesquisa do ICMBio, que atuam em parceria com instituições de pesquisa, sociedades científicas e organizações não governamentais (ONGs) de reconhecida atuação na área de conservação da biodiversidade.</p>
                    <p class="text-justify">Ao longo deste processo, alguns desses atores possuem funções distintas do conjunto de especialistas. São eles:</p>
                </div>
            </div>
        </div>
        <div class="table-list d-flex align-items-center justify-content-center">
            <button class="table-button"  @click="activeBtn = 'btn1'" :class="{active: activeBtn === 'btn1' }">Coordenação do Processo</button>
            <button class="table-button"  @click="activeBtn = 'btn2'" :class="{active: activeBtn === 'btn2' }">Coordenadores de Táxon</button>
            <button class="table-button"  @click="activeBtn = 'btn3'" :class="{active: activeBtn === 'btn3' }">Pontos Focais</button>
            <button class="table-button"  @click="activeBtn = 'btn4'" :class="{active: activeBtn === 'btn4' }">Equipe de Apoio</button>
            <button class="table-button"  @click="activeBtn = 'btn5'" :class="{active: activeBtn === 'btn5' }">Validadores</button>
        </div>
        <div class="separator"></div>
        <TablePC v-show="activeBtn == 'btn1'"/>
        <TableTC v-show="activeBtn == 'btn2'"/>
        <TableST v-show="activeBtn == 'btn3'"/>
        <TableFP v-show="activeBtn == 'btn4'"/>
        <TableVT v-show="activeBtn == 'btn5'"/>
        <div class="col-12 text-right mb-5 mt-2 d-md-none d-block">
            <p><b>Deslize para o lado para ver todas as informações</b> 👉</p>
        </div>
    </div>

</template>
<script>

import api from '@/services/api.js'
import InfoBall from '@/web/components/InfoBall.vue'
import TablePC from '@/web/components/TableProcessCoordinationTeam.vue'
import TableTC from '@/web/components/TableTaxonCoordinators.vue'
import TableFP from '@/web/components/TableFocalPoints.vue'
import TableST from '@/web/components/TableSupportTeam.vue'
import TableVT from '@/web/components/TableValidatorsTeam.vue'

export default {
    components: {
        InfoBall,
        TablePC,
        TableTC,
        TableFP,
        TableST,
        TableVT
    },
    data() {
        return {
            items: [],
            activeBtn:'btn1'
        }
    },
    created() {
        var t = this
        api.get('/redBooks').then(response => (
            t.items = response.data.data
        ))
    },
    methods: {
    }
}
</script>

<style scoped>
    .text-justify{
        text-align: justify;
    }
    .ball-list {
        margin-top: -4rem;
    }
    .table-button {
        background-color: transparent;
        margin-left: 25px;
        margin-right: 25px;
        border: none;
        border-bottom: 7px solid #ffffff;
    }
    .active {
        font-weight: bold;
        border-bottom: 7px solid #E2F5A1;
    }
    .separator {
        margin-bottom: 30px;
        border-bottom: 3px solid #E2F5A1;
        margin-left: 12vw;
        margin-right: 12vw;
    }
    .stripe-us {
        background-image: url(/img/animals/verme-full.png);
        background-size: cover;
        height: 350px;
        padding-top: 10rem;
    }
    .tit1 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #fff;
        border-top: 3px solid white;
        padding-top: 1.5rem;
    }
    .tit2 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #fff;
    }
@media screen and (max-width: 600px) {
    .table-button {
        background-color: transparent;
        margin-left: 0px;
        margin-right: 0px;
        border: none;
        border-bottom: 7px solid #ffffff;
        font-size: .8rem;
    }
    .ball-list {
        margin-top: -1rem;
    }
    .stripe-us{
        height: 115px;
        padding-top: 40px;
    }
    .tit1 {
    font-size: 1.3rem;
    padding-top: .5rem;
    }
    .tit2 {
        font-size: 1.3rem;
    }
}
</style>
